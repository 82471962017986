import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const transferOutList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'transferout/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get trasfer out list err : ', err)

      return {}
    })

  return response
}

const transferOutGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'transferout',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('get trasfer out err : ', err)

      return {}
    })

  return response
}
export default { transferOutList, transferOutGet }
