<template>
  <v-chip
    :color="status == 1?'warning':status == 2?'success':'error'"
    :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:status==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
    small
  >
    {{ status == 1 ? $t('waiting_check') : status == 2 ? $t('successful_transfer') :$t('cancel') }}
  </v-chip>
</template>

<script>

export default {
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
}
</script>
